import IconPause from '@/assets/icons/icon-pause-white.svg?react';
import IconPlay from '@/assets/icons/icon-play.svg?react';
import avatarFrame1 from '@/assets/imgs/avatar-frames/avatar-1.png';
import avatarFrame2 from '@/assets/imgs/avatar-frames/avatar-2.png';
import avatarFrame5 from '@/assets/imgs/avatar-frames/avatar-5.png';
import { cn } from '@/lib/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

// Map voice names to their sample audio files
const voiceSampleMap: Record<string, string> = {
  Biology: '/voice-samples/will_biology.mp3',
  History: '/voice-samples/scarlett_history.mp3',
  'Comp Sci': '/voice-samples/dan_computer-science.mp3',
};

const voices = [
  {
    name: 'Biology',
    avatar: avatarFrame1,
    description: 'Listen to biology papers with natural voice',
  },
  {
    name: 'History',
    avatar: avatarFrame2,
    description: 'Experience history papers with human-like narration',
  },
  {
    name: 'Comp Sci',
    avatar: avatarFrame5,
    description: 'Computer science papers read naturally',
  },
];

const AudioBars = ({
  isPlaying,
  voiceName,
  onComplete,
  usingRealAudio,
  duration,
}: {
  isPlaying: boolean;
  voiceName: string;
  onComplete: () => void;
  usingRealAudio: boolean;
  duration: number;
}) => {
  const [activeBarIndex, setActiveBarIndex] = useState(-1);
  const intervalIdRef = useRef<NodeJS.Timeout>();
  const timeoutIdRef = useRef<NodeJS.Timeout>();

  // Generate pseudo-random but consistent heights based on voice name
  const [heights] = useState(() => {
    // Simple seed function based on voice name
    const seed = voiceName
      .split('')
      .reduce((acc, char) => acc + char.charCodeAt(0), 0);

    // Use the seed to generate a consistent pattern for this voice
    const pseudoRandom = (index: number) => {
      const value = Math.sin(seed + index) * 10000;
      return Math.floor(Math.abs(value) % 12) + 4; // Heights between 4 and 16
    };

    // Generate 24 bar heights
    return Array.from({ length: 24 }, (_, index) => pseudoRandom(index));
  });

  // Reset animation when not playing
  useEffect(() => {
    if (!isPlaying) {
      setActiveBarIndex(-1);
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }
  }, [isPlaying]);

  // Handle animation when playing
  useEffect(() => {
    if (!isPlaying) return;

    // Calculate interval based on audio duration or use default for visual simulation
    const intervalDuration =
      usingRealAudio && duration > 0
        ? (duration * 1000) / 24 // Divide total duration by number of bars
        : 300; // Default interval for visual simulation

    const updateBarIndex = () => {
      setActiveBarIndex((prev) => {
        const next = prev + 1;
        if (next >= 24) {
          // Only complete if we're not using real audio
          if (!usingRealAudio) {
            if (intervalIdRef.current) {
              clearInterval(intervalIdRef.current);
            }
            // Schedule onComplete callback to run after state update
            timeoutIdRef.current = setTimeout(onComplete, 0);
            return -1;
          }
          return 23; // Stay at the last bar for real audio
        }
        return next;
      });
    };

    intervalIdRef.current = setInterval(updateBarIndex, intervalDuration);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [isPlaying, onComplete, usingRealAudio, duration]);

  return (
    <div
      className="flex h-4 items-center"
      role="progressbar"
      aria-label={`Audio visualization for ${voiceName}`}
      aria-valuemin={0}
      aria-valuemax={24}
      aria-valuenow={activeBarIndex + 1}
    >
      {heights.map((height, index) => (
        <div
          key={`${voiceName}-bar-${String(index)}`}
          className={cn(
            'w-px rounded-full transition-colors duration-300',
            isPlaying && index <= activeBarIndex
              ? 'bg-blue-500'
              : 'bg-[#E4EDF3]',
          )}
          style={{
            height: `${String(height)}px`,
            marginRight: index === heights.length - 1 ? '0' : '1px',
          }}
          aria-hidden="true"
        />
      ))}
    </div>
  );
};

export function HeroVoices() {
  const [playingVoice, setPlayingVoice] = useState<string | null>(null);
  const audioPlayer = useGlobalAudioPlayer();

  // Track if we're using real audio or visual simulation
  const [usingRealAudio, setUsingRealAudio] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);

  // This function will force stop the audio and dispatch an event
  const forceStopAudio = useCallback(() => {
    try {
      // Stop using the audioPlayer API
      audioPlayer.pause();
    } catch (e) {
      console.error('Error in forceStopAudio:', e);
    }

    // Clear our state regardless of success/failure
    setPlayingVoice(null);
    setUsingRealAudio(false);

    // Dispatch an event to signal that audio has stopped
    window.dispatchEvent(new CustomEvent('audioStopped'));
  }, [audioPlayer]);

  // Listen for the stopAllWavesurfers event to stop our audio too
  useEffect(() => {
    const handleStopAllAudio = () => {
      if (playingVoice && usingRealAudio) {
        forceStopAudio();
      }
    };

    window.addEventListener('stopAllWavesurfers', handleStopAllAudio);
    return () => {
      window.removeEventListener('stopAllWavesurfers', handleStopAllAudio);
    };
  }, [audioPlayer, playingVoice, usingRealAudio, forceStopAudio]);

  // Handle playing a voice sample
  const handlePlayVoice = (voiceName: string) => {
    // If this voice is already playing, stop it
    if (playingVoice === voiceName) {
      forceStopAudio();
      return;
    }

    // If another voice is playing, stop it first
    if (playingVoice) {
      // Set up the event listener for the audioStopped event
      const handleAudioStopped = () => {
        playAudioSample(voiceName);
        // Clean up the listener after it's used
        window.removeEventListener('audioStopped', handleAudioStopped);
      };

      // Add the listener before stopping the current audio
      window.addEventListener('audioStopped', handleAudioStopped);

      // Stop the current audio
      forceStopAudio();
    } else {
      // Nothing playing, so play immediately
      playAudioSample(voiceName);
    }
  };

  // Play an audio sample using a more controlled approach
  const playAudioSample = (voiceName: string) => {
    // Set the new playing voice immediately for UI responsiveness
    setPlayingVoice(voiceName);

    // Get the sample path
    const samplePath = voiceSampleMap[voiceName];
    if (!samplePath) {
      setUsingRealAudio(false);
      return;
    }

    try {
      // Dispatch event to stop other audio
      const stopEvent = new CustomEvent('stopAllWavesurfers');
      window.dispatchEvent(stopEvent);

      // Use the audioPlayer API
      audioPlayer.load(samplePath, {
        format: 'mp3',
        autoplay: true,
        html5: true,
        onload: () => {
          if (playingVoice === voiceName) {
            setUsingRealAudio(true);
            setAudioDuration(audioPlayer.duration || 0);
          }
        },
        onend: () => {
          setPlayingVoice(null);
          setUsingRealAudio(false);
          setAudioDuration(0);
        },
      });
    } catch (error) {
      console.error('Error in playAudioSample:', error);
      // Fall back to visual simulation
      setUsingRealAudio(false);
    }
  };

  return (
    <div
      className="mt-12 flex w-full flex-col items-center justify-center gap-4 px-4 lg:flex-row lg:gap-6"
      role="region"
      aria-label="Voice samples"
    >
      {voices.map((voice) => (
        <div
          key={voice.name}
          className="flex w-full max-w-[360px] items-center justify-between gap-3 rounded-[500px] border-[5px] border-[#E4EDF3] bg-white py-3 pl-3 pr-6 shadow-sm transition-all duration-200 hover:border-[#d3e0e9] hover:shadow-md sm:gap-6 sm:pr-8 lg:w-auto"
          role="article"
          aria-label={`${voice.name} voice sample`}
        >
          <div className="flex items-center gap-2 sm:gap-3">
            <img
              src={voice.avatar.src}
              alt={`${voice.name} voice avatar`}
              className="size-7 rounded-full sm:size-8"
              loading="eager"
              width={32}
              height={32}
            />
            <span
              className="text-nowrap text-sm font-medium sm:text-base"
              aria-label={voice.description}
            >
              {voice.name}
            </span>
          </div>

          <AudioBars
            isPlaying={playingVoice === voice.name}
            voiceName={voice.name}
            usingRealAudio={usingRealAudio && playingVoice === voice.name}
            duration={audioDuration}
            onComplete={() => {
              // Only handle completion for visual simulation
              if (!usingRealAudio && playingVoice === voice.name) {
                setPlayingVoice(null);
              }
            }}
          />

          <button
            type="button"
            onClick={() => {
              handlePlayVoice(voice.name);
            }}
            className={cn(
              'flex size-6 shrink-0 items-center justify-center rounded-full bg-black',
              'transition-transform hover:scale-110 active:scale-95',
            )}
            aria-label={`${playingVoice === voice.name ? 'Pause' : 'Play'} ${voice.name} voice sample`}
            aria-pressed={playingVoice === voice.name}
          >
            {playingVoice === voice.name ? (
              <IconPause className="size-2 text-white" aria-hidden="true" />
            ) : (
              <IconPlay className="size-3 text-white" aria-hidden="true" />
            )}
          </button>
        </div>
      ))}
    </div>
  );
}
